div#heading_area,
.footerWrapper {
    display: block;
}
.lc_content_full.lc_swp_boxed.lc_basic_content_padding {
    padding: 0;
	max-width: 100%;
}
html{
    font-family: 'Open Sans', sans-serif !important;
}
body {
	font-family: 'Open Sans', sans-serif !important;
}

p:empty{
	display: none;
}

hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible
}
pre {
	font-family: monospace, monospace;
	font-size: 1em
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}
b, strong {
	font-weight: bolder
}
code, kbd, samp {
	font-family: monospace, monospace;
	font-size: 1em
}
small {
	font-size: 80%
}
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}
sub {
	bottom: -.25em
}
sup {
	top: -.5em
}
img {
	border-style: none
}
button, input, optgroup, select, textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0
}
button, input {
	overflow: visible
}
button, select {
	text-transform: none
}
[type=button], [type=reset], [type=submit], button {
-webkit-appearance:button
}
[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
border-style:none;
padding:0
}
[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
outline:1px dotted ButtonText
}
fieldset {
	padding: .35em .75em .625em
}
legend {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal
}
progress {
	vertical-align: baseline
}
textarea {
	overflow: auto
}
[type=checkbox], [type=radio] {
-webkit-box-sizing:border-box;
box-sizing:border-box;
padding:0
}
[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
height:auto
}
[type=search] {
-webkit-appearance:textfield;
outline-offset:-2px
}
[type=search]::-webkit-search-decoration {
-webkit-appearance:none
}
::-webkit-file-upload-button {
-webkit-appearance:button;
font:inherit
}
details {
	display: block
}
summary {
	display: list-item
}
template {
	display: none
}
[hidden] {
display:none
}
.clearFix:after, .clearFix:before {
	content: "";
	display: block;
	clear: both
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none
}
h1, h2, h3, h4, p {
	margin: 0;
	padding: 0
}
.acv-checkout .container {
	max-width: 1140px;
	display: block;
	margin: 0 auto
}
.order {
    padding: 36px 0;
}
.order__header_top {
	color: #fff;
	font-size: 17px;
	display: block;
	text-align: center;
	padding: 5px 0;
	background: #000
}
.order__header_top span {
	color: #661997
}
.views-coll {
	position: relative;
	padding-left: 41px
}
.views-coll:before {
	content: "";
	display: block;
	width: 31px;
	height: 18px;
	background: url(../images/eye.png) no-repeat center;
	position: absolute;
	left: 0;
	top: 0
}
.order__header_in img {
	max-width: 185px;
	float: left;
	margin: 25px 0 8px;
}
.delivery-block {
	float: left;
	max-width: 220px;
	padding-left: 153px;
	font-size:.875rem;
	position: relative;
	margin-left: 100px;
	margin-top: 28px;
    min-width: 375px;
}
.delivery-block:before {
	content: "";
	background: url(../images/ca.png) no-repeat center;
	width: 148px;
	height: 38px;
	background-size: contain;
	position: absolute;
	left: 0;
	top: -2px
}
.acv-checkout .wrapper {
	/* background: -webkit-gradient(linear, left top, left bottom, from(#f7ffd3), color-stop(31%, #f7ffd3), color-stop(50%, #fff), to(#fff)); */
	background: -o-linear-gradient(top, #f7ffd3 0, #f7ffd3 31%, #fff 50%, #fff 100%);
	background: linear-gradient(to bottom, #f7ffd3 0, #f7ffd3 31%, #fff 50%, #fff 100%);
    margin: 0;
	height: auto;
	padding: 0;
}
.order__left {
	width: 65%;
	float: left
}
.order__right {
	width:35%;
	padding: 0 15px;
	float: left
}
.steps {
	padding: 15px;
	background: #fff;
	border:.0625rem solid #ccc;
	font-size: 14px;
}
.steps__list{
	padding: 0;
}
.steps__item {
	float: left;
	width: 33.3%;
	line-height: 36px;
	text-align: center;
	color: #000;
	font-size: 16px;
	background-color: #ebebeb
}
.steps__item.active {
	background-color: #fd6f03;
	color: #fff
}
.approved-text {
	color: #393f40;
font-size:.875rem;
margin-top:.9375rem;
	margin-bottom: 5px
}
.approved-text span {
	color: #661997
}
.steps p {
font-size:.875rem;
	line-height: 18px;
	color: #393f40
}
.steps p span {
	color: #fc0303
}
.package-item__header:after, .package-item__header:before {
	content: "";
	display: block;
	clear: both
}
.title-block {
	width: 71%;
	float: left;
	background: #7ec304;
	height: 38px;
	line-height: 37px;
	padding-top: 3px
}
.title-block__main {
	font-weight: 700;
	font-size: 25px;
	letter-spacing: .01em;
	text-transform: uppercase;
	color: #fff9ef;
	line-height: 32px;
	padding-left: 15px;
	float: left
}
.title-block__retail {
	color: #fff9ef;
	font-size: 12px;
	line-height: 13px;
	margin-left: 55px;
	padding-top: 3px;
	float: left
}
.product3 .title-block__retail {
	margin-left: 117px
}
.package-item__header {
	overflow: hidden;
	margin: 0 -1px;
	border-radius: 10px 10px 0 0;
	font-family: Arial, Helvetica, sans-serif
}
.shipping-row {
	float: left;
	width: 29%;
	background: #f704b6;
	line-height: 35px;
	position: relative;
	height: 38px;
	font-weight: 400
}
.shipping-row:before {
	content: "";
	display: block;
	clear: both;
	position: absolute;
	left: -28px;
	top: 2px;
	width: 30px;
	height: 60px;
	background: #f704b6;
	-ms-transform: rotate(49deg);
	-webkit-transform: rotate(49deg);
	transform: rotate(49deg);
	z-index: 2
}
.shipping-row span {
	position: relative;
	z-index: 3;
	color: #fff9ef;
	display: block;
	padding-left: 31px;
	line-height: 35px;
	font-size: 18px
}
.acv-checkout .shipping-row span{
	line-height: 40px;
}
.shipping-row span:before {
	content: "";
	display: block;
	position: absolute;
	/* left: -8px;
	top: 6px; */
	left: 0px;
    top: 8px;
	width: 28px;
	height: 23px;
	background: url(../images/box-icon.svg) no-repeat center;
	background-size: contain
}
.footnote {
	font-size: 11px;
	width: 630px;
	max-width: 100%;
	display: block
}
.package-item {
	background: #fff;
	border-left: 1px solid #d2d2d2;
	border-right: 1px solid #d2d2d2;
	border-bottom: 1px solid #d2d2d2;
	border-radius: 10px;
	margin-bottom: 14px;
	cursor: pointer;
}
.package-item__content:after, .package-item__content:before {
	content: "";
	clear: both;
	display: block
}
.package-item__status {
	float: left;
	width: 7%;
	height: 160px
}
.package-item__status span {
	width: 30px;
	height: 30px;
	display: block;
	margin-top: 58px;
	position: relative;
	border: 4px solid #59aeee;
	left: 7px;
	top: 6px;
	border-radius: 50%
}
.product.active .package-item__status span:before {
	content: "";
	display: block;
	background: url(../images/done-icon.png) no-repeat center;
	position: absolute;
	left: -4px;
	top: -35px;
	width: 58px;
	height: 58px;
	background-size: contain
}
.package-images {
	float: left;
	position: relative;
	width: 60%;
	padding: 2px 10% 0 2%;
	display: block
}
.package-images__item {
    float: left;
    /* width: 50%; */
    height: 160px;
    position: relative;
	width: 100%;
	padding-top: 20px;
	padding-right: 65px;
	text-align: center;
}
/* .package-images__item .prod-btl1, .prod-btl2, .prod-btl3, .prod-btl4, .prod-btl5{
    position: absolute;
    top: 35px;
    left: 48px;
    width: 75px;
	z-index:4;	
} */
.package-images__item img{
	max-height: 100%;
}
.prod-btl2{
    width: 71px;
    left: 90px;
    top: 35px;
	z-index:2;
}
.prod-btl3{
	top: 35px;
	left:10px;
	width: 71px;
	z-index:2;
}
.prod-btl4{
	left: 17px;
    top: 35px;
    width: 71px;
}
.prod-btl5{
	left:60px;
	}
.chk-plus{
    position: absolute;
    top: 68px;
	margin-left: -45px;
    left: 50%;
    z-index: 9;
    width: 36px;	
}

.package-info {
	float: left;
	width: 32%
}
.package-info__price {
	display: block;
	font-size: 42px;
	line-height: 36px;
	word-spacing: -5px;
	margin-left: -7px;
	margin-top:3px;
}
.package-info__price .CTE--text{
	font-size: 42px;
	line-height: 36px
}
.perBottle{
	display: block;
}
.package-info__price span {
	font-size: 15px;
	margin-left: -5px;
	display: inline-block;
	padding-left: 5px;
}
.check-package-info__title {
	font-size: 16px;
	display: block;
	line-height: 19px;
	font-style: italic;
	margin-top: 13px;
	margin-bottom: 3px;
	letter-spacing: -.04em;
	color: #f704b6;
	font-weight: 700;
	padding-left: 3px
}
.package-info__subTitle {
	display: block;
	font-size: 14px;
	text-align: center;
	padding-right: 23px;
	letter-spacing: .1em
}
.package-info__btn {
	font-family: Oswald, sans-serif;
	min-width: 77%;
	height: 33px;
	line-height: 31px;
	display: inline-block;
	text-align: center;
	font-size: 17px;
	background: #fc6806;
	border-radius: 3px;
	color: #fff;
	padding: 0 10px;
	margin-left: 4px;
	-webkit-box-shadow: 2px 2px 1px 0 rgba(0,0,0,.2);
	box-shadow: 2px 2px 1px 0 rgba(0,0,0,.2);
	/*letter-spacing: .03em*/
	word-spacing: normal;
}
.product .package-item__content{
	padding-bottom: 10px;
}
.product.active .package-item__content {
	background: #fdfbe2;
	border-radius: 0 0 10px 10px;
	
}
.package__save {
	background: url(../images/package-save.png) no-repeat;
	position: absolute;
	right: 10%;
	bottom: 33px;
	width: 95px;
	height: 95px;
	background-size: cover;
	color: #000;
	font-family: Oswald, sans-serif;
	z-index:10;
}
.package__save_title {
	font-size: 17px;
	line-height: 20px;
	font-weight: 500;
	display: block;
	text-align: center;
	margin-top: 27px
}
.package__save_item {
	text-align: center;
	display: block;
	font-size: 16px;
	letter-spacing: -.02em
}
.product-selection {
	border:.0625rem solid #ccc;
	background-color: #fff;
	padding: 19px 17px;
	margin-top:.625rem
}
.product-selection select.form-control {
    margin-bottom: 15px;
}
.guarantee-block {
	border: 1px solid #979797;
	border-radius: 10px;
	overflow: hidden;
	text-align: center;
	font-family: Montserrat, Arial, Helvetica, sans-serif;
	margin-bottom: 17px
}
.guarantee-top {
	background-color: #6b0d7d;
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
	padding: 3px 5px 4px
}
.guarantee-content {
	overflow: hidden;
	padding: 7px 15px 10px 5px
}
.guarantee-icon {
	float: left;
	width: 23%;
	margin: 4px 3% 0
}
.guarantee-text {
	overflow: hidden;
	font-size: 15px;
}
.guarantee-text p {
	margin: 0;
	font-size: 15px;
	line-height: 24px;
	font-family: inherit;
	font-weight: 400;
	color: #393f40
}
.line {
	padding: 1rem 0;
	margin-left: -17px;
	margin-right: 0px;
	background-image: -o-linear-gradient(to bottom, #fe8814 0, #fc5603 72%, #fb5514 100%);
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#fe8814), color-stop(72%, #fc5603), to(#fb5514)); */
	background-image: -o-linear-gradient(top, #fe8814 0, #fc5603 72%, #fb5514 100%);
	background-image: linear-gradient(to bottom, #fe8814 0, #fc5603 72%, #fb5514 100%);
	text-align: center
}
.arrow {
	position: absolute;
	right: -20px;
	top: 0;
	width: 20px;
	height: 4rem;
	background-image: url(../images/arrow-end2.png);
	background-repeat: no-repeat;
	background-size: contain
}
.line-block {
	margin: 36px 0 25px
}
.line .text-center {
	color: #fff;
	font-size: 1.375rem;
	font-weight: 700;
	font-style: italic
}
.line-block {
	position: relative
}
.form__header {
	text-align: center;
padding:.625rem 0;
	display: inline-block;
	background-color: #323232;
	width: 100%
}
.form__header h2, .form__header h3 {
	color: #fff;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 2.5rem;
	line-height: 1.1;
margin-top:.2rem;
margin-bottom:.5rem;
	font-weight: 700;
	font-style: normal
}
.form__header h3 {
	font-size: 1.25rem;
	font-weight: 400
}
.form__in {
	position: relative;
	padding-bottom: 20px;
	width: 100%;
	background-color: #ebebeb;
	font-size: 15px;
}
.accept-text {
	display: block;
	text-align: center;
	padding: 20px 15px 0
}
.form__cards {
	text-align: center;
	padding-left: 0;
}
.form__cards li {
	display: inline-block;
	margin:10px 5px;
}
.form__cards li img {
	height: 33px;
	width: auto
}
.form__in .form {
	padding: 0 15px
}
.form__in .form label {
	font-size: 14px;
	cursor: pointer;
	display: block;
	font-weight: 400;
	line-height: 1.2;
	color: #000;
	margin-bottom:5px ;
}
.form__in .checkboxLabel.acvCheck {
	font-weight: 600;
}
.form__in .checkboxLabel.acvCheck input[type="checkbox"]{
    top: 0px !important;
} 
/* .acv_check-area.check_area [type="checkbox"]:not(:checked)~label:before,
.acv_check-area.check_area [type="checkbox"]:checked~label:before{
	transform: none;
    top: 0px !important;
} */
/* .check_area.acv_check-area [type="checkbox"]:not(:checked)~label:after, .check_area [type="checkbox"]:checked~label:after{
	transform: rotate(-45deg) translate(8px, -5px);
} */

/* .formBox .check_area [type="checkbox"] ~label:after {
    transform: rotate(-45deg) translate(8px, -5px);
} */
select {
	-webkit-appearance: none!important;
	background-color: #fff;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==);
	background-repeat: no-repeat;
	background-position: 94% center
}
.form__in .form label.checkboxLabel {
    margin-left: 10px;
	text-align: left;
}
.form__in .form label.checkboxLabel input#shippingCheckbox {
    position: absolute;
	left: 0;
    top: 0;
}
.form__in input,
.form__in input[type=number],
.form__in select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #fff !important	; 
	font-family: inherit;
	border: 1px solid #989898;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
	box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
	color: rgba(0,0,0,.75);
	display: block;
	font-size: 1rem;
	margin: 0 0 1rem!important;
	padding:.5rem;
	height: 2.4375rem;
	outline: 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: border-color .45s ease-in-out, -webkit-box-shadow .45s;
	transition: border-color .45s ease-in-out, -webkit-box-shadow .45s;
	-o-transition: box-shadow .45s, border-color .45s ease-in-out;
	transition: box-shadow .45s, border-color .45s ease-in-out;
	transition: box-shadow .45s, border-color .45s ease-in-out, -webkit-box-shadow .45s;
	border-radius: 0;
}

#cc_exp_month{
	margin-right: 5px !important;
}
#cc_exp_year{
	margin-left: 5px !important;
}

select#mm_field_cc_exp_month ~  .select2-container{
	margin-right: 5px;
}
.select2-container .select2-selection--single {
    background: #fff;
    border-radius: 0;
    height: 38px;
    margin-bottom: 0;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);
    color: rgba(0,0,0,.75);
    border: 1px solid #989898;
    font-size: 13px;
	color: #989898;
}
.form__in .select2-container .select2-selection--single{
	margin-bottom: 15px !important;
}
.form__in .form .clearFix .form-holder {
	width: 48%;
	float: left
}
.form__in .form .clearFix .form-holder.col-md-12{
	width: 100%;
}
.form__in .form .clearFix .form-holder:first-child {
	margin-right: 4%
}
.secure-icon {
	max-width: 100%
}
.form__in .send-btn,
.form__in .btn-wrapper .mm-button {
	background-color: #71c105 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    display: block;
    width: 100% !important;
    height: 70px;
    font-size: 22px;
    text-indent: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    outline: none;
    font-family: 'Open Sans', sans-serif !important;
    line-height: 48px;
    position: relative;
    text-align: center;
    padding: 10px 15px;
	text-decoration: none;
}
.send-btn:hover {
	background: #80da06!important
}
.send-btn span {
	position: relative
}
.send-btn span:before ,
.form__in .btn-wrapper .mm-button:before{
	content: "";
    display: block;
    width: 9px;
    height: 25px;
    background: url(../images/arrow-white.svg) no-repeat center;
    position: absolute;
    left: 25px;
    right: auto;
    top: 22px;
}
.secure-icon {
	text-align: center;
	margin-bottom: 19px
}
.secure-icon span {
font-size:.75rem;
	position: relative
}
.secure-icon span:before {
	content: "";
	width: 10px;
	height: 12px;
	background: url(../images/lock-img.svg) no-repeat center;
	position: absolute;
	left: -14px;
	top: 2px
}
.acv-checkout .date-container {
	color: #661997!important
}
.acv-checkout .order__header_top span{
	color: #ee226e;
}
#cvv, .form-fields #expire_m, .form-fields #expire_y {
	width: 48%;
	display: inline-block
}
.form-fields #expire_m {
	margin-right: 4%
}
.footer-part{
	padding-left: 0;
}
.footer-part li {
	float: left;
	width: 25%;
	padding: 0 2.5%;
	text-align: center;
	margin-bottom: 25px;
}
.footer-part__img {
	width: 100%;
	height: 65px;
	position: relative
}
.footer-part__img img {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}
.cvv-link {
	width: 100%;
	display: block;
    float: left;
}
.cvv-link a {
    margin: 10px 0 0 0px;
    font-family: 'Open Sans', sans-serif;
    color: rgb(0, 0, 238);
    font-weight: 600;
    font-size: 15px;
    text-decoration: underline;
	display: block
}
.cvv-image {
    display: none;
    text-align: center;
    padding: 0 0 0;
}

.cvv-image.show{
	display: block;
}

.cvv-image img {
    width: 190px;
    margin: auto;
}
.checkout-footer {
	padding-bottom: 50px;
	font-size:12px;
}
.checkout-footer p {
	font-size:12px;
	text-align: center;
	line-height: 1.4;
	margin-bottom: 1.25rem
}
.terms-links {
	text-align: center;
	margin-top: 30px;
	padding-left: 0;
}
.terms-links li {
	display: inline-block
}
.terms-links li a {
	color: #000!important;
	font-size:.75rem;
	text-decoration: none
}
.checkout-footer .footer_pj {
	text-align: center;
	font-family: inherit;
	font-weight: 400;
	font-size: 1rem;
	margin-top: 15px;
	line-height: 1.4;
	margin-bottom: 30px !important;
	text-rendering: optimizeLegibility;
}
.acv-checkout .footer {
	color: #393f40
}
.payment_as_shipping_label {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px
}
.payment_as_shipping_label input {
	margin:-3px 5px 10px 0 !important;
}
.billing-form .form-holder {
font-size:.75rem
}
.billing-title {
	display: block;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 10px
}
.billing-form .form-holder {
	/* margin-bottom: 16px */
}

.product2 .package-images__item::before {
	right: -28px
}

.custom-social-proof {
	position: fixed;
	bottom: 0px;
	left: 20px;
	z-index: 9999999999999!important;
	font-family: 'Open Sans', sans-serif;
	display: none
}
.custom-notification {
	width: 320px;
	border: 0;
	text-align: left;
	z-index: 99999;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-weight: 400;
	border-radius: 6px;
	padding-bottom: 7px;
	-webkit-box-shadow: 2px 2px 10px 2px hsla(0,4%,4%,.2);
	box-shadow: 2px 2px 10px 2px hsla(0,4%,4%,.2);
	background-color: #fff;
	position: relative;
	cursor: pointer
}
.custom-notification-container {
	display: block!important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 320px;
	width: 100%;
	height: 80px
}
.custom-notification-image-wrapper {
	float: left;
	width: 80px;
	margin-top: 5px
}
.custom-notification-image-wrapper img {
	max-height: 75px;
	width: auto;
	display: block;
	margin: 0 auto 5px;
	overflow: hidden;
	border-radius: 6px 0 0 6px
}
.custom-notification-content-wrapper {
	margin: 0;
	height: 100%;
	color: gray;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 0 6px 6px 0;
	padding-bottom: 5px
}
.custom-notification-content {
	font-family: inherit!important;
	padding: 0!important;
	font-size: 14px;
	line-height: 16px;
	width: calc(100% - 70px);
	float: left;
	margin-left: 10px;
	padding-bottom: 5px;
	margin-top: 10px
}
.custom-notification-content small {
	margin-top: 3px!important;
	display: block!important;
	font-size: 12px!important;
	opacity: .8
}
.custom-close {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 12px;
	width: 12px;
	cursor: pointer;
	-webkit-transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	transition: .2s ease-in-out;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0
}
.custom-close:before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: gray;
	position: absolute;
	left: 0;
	top: 5px
}
.custom-close:after {
	content: "";
	display: block;
	height: 100%;
	width: 2px;
	background-color: gray;
	position: absolute;
	left: 5px;
	top: 0
}
.custom-close:hover .custom-close {
	opacity: 1
}
.custom-social-proof .custom-notification .custom-close::before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: gray;
	position: absolute;
	left: 0;
	top: 5px
}
.custom-social-proof .custom-notification .custom-close::after {
	content: "";
	display: block;
	height: 100%;
	width: 2px;
	background-color: gray;
	position: absolute;
	left: 5px;
	top: 0
}
.custom-social-proof .custom-notification:hover .custom-close {
	opacity: 1
}

/*---------------------------
LOADING BAR
--------------------------*/
.popup-loading-wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1100;
	background: rgba(0, 0, 0, 0.95);
	text-align:center;
	}
.popup p {margin: 0}
.popup-loading-wrapper .popup {
	min-width: 300px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
	}
.popup-loading-wrapper .popup h2 {
	width: 300px;
	font-size: 32px;
	color: #fff;
	line-height: 36px;
	padding: 7px 0 22px
	}
.popup-loading-wrapper .popup h3 {
	line-height: normal;
	padding: 0 0 24px;
	color: #fff
	}
.popup-loading-wrapper .popup p {
	opacity: .5;
	font-size: 18px;
	padding: 8px 0 3px
	}
.popup-loading-wrapper .popup .product-image {
	width: 206px;
	height: 207px;
	margin: 0 auto 12px;
	position: relative;
	/* background: url(../images/product.png) no-repeat center center; */
	background-size: contain;
	}
.popup-loading-wrapper .popup .loading-image {
    width: 70px;
    height: 70px;
    margin: auto;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
	}

@-moz-keyframes spin {
100% {-moz-transform: rotate(360deg)}
}

@-webkit-keyframes spin {
100% {-webkit-transform: rotate(360deg)}
}

@keyframes spin {
100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
}

.loading-screen{
	position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    background: rgba(0,0,0,0.7);
    z-index: 999;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
	}
.loading-pop{
	float:left;
	width:100%;
	padding:25px 0;
	text-align:center;
	}
.pop-content{
	display:inline-block;
	vertical-align:middle;
	width:90%;
	max-width:500px;
	background:#fff;
	border-radius:10px;
	padding:10px;
	}
.loading-img{
	display:inline-block;
	vertical-align:middle;
	margin-right:10px;
	}
ul.pop-list{
	display:inline-block;
	vertical-align:middle;
	width:230px;
	margin:10px 0;
	}
ul.pop-list li{
	float:left;
	width:100%;
	color: #134f7a;
    font-size: 19px;
	line-height:24px;
	letter-spacing:0.5px;
	text-align:left;
	margin:3px 0;
	padding-left:30px;
	opacity:0.5;
	position:relative;
	transition:all ease 0.6s;
	
	}
ul.pop-list li img{
	position:absolute;
	left:0;
	top:6px;
	width:20px;
	opacity:0;
	transform:translateX(-10px);
	}
ul.pop-list li.active{
	opacity:1;
	
	transition:all ease 0.5s;
	}
ul.pop-list li.active img{opacity:1; transition:all ease 0.2s; transform:translateX(0);}
.chkbox {
    width: auto !important;
    height: auto !important;
    appearance: checkbox !important;
    outline: auto !important;
    display: inline !important;
    text-align: center !important;
}

label.payment_as_shipping_label {
    text-align: center;
    display: inline;
    font-size: 16px !important;
}
.yellowbox {
    border: 2px dotted red;
    background: #ffe11d;
    padding: 10px;
    display: table;
    position: relative;
    width: 100%;
}
.yellowbox i, .check_area {
    display: table-cell;
    vertical-align: middle;
}
.check_area {
    padding-left: 10px;
}
.form__in  .check_area label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
}

.form__in  .check_area #checkD{
	position: absolute;
	top: 15px;
}

.top-terms {
    clear: both;
    text-align: left;
    width: 100%;
    margin: 25px auto 20px;
}
.top-termsHdng {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 30px 15px 2px;
}
.top-termsHdng:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 50%;
    color: #afafaf;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    background-size: 100%;
    transform: translateY(-50%);
}
.top-terms.open .top-termsHdng:after {
    content: '-';
}
.top-trends-desc {
    display: none;
}
.open .top-trends-desc {
    display: block;
}
.top-trends-desc p {
    padding: 5px 3px 5px;
    text-align: left;
    color: #3d3d3d;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 1px;
    margin: 0;
}

.form__in .form .expiryholder .phone-12.columns.form-holder {
	width: 100%;
    display: flex;
    margin-bottom: 15px;
}
.form__in .form .expiryholder .phone-12.columns.form-holder > div:first-child{
	width: 50%;
	margin-right: 5px;
	margin-bottom: 15px;
}
.form__in .form .expiryholder .phone-12.columns.form-holder > div:last-child{
	width: calc(50% - 10px);
	margin-left: 5px;
	margin-bottom: 15px;
}
.cc-month select, .cc-year select {
    margin-bottom: 0 !important;
}
.fixed-btn {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100%;
    z-index: 21;
}

.proLand-footer .btn__send.loading-btn {
    background-color: #5fbb49;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: -o-linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(#a3cc2c), to(#5fbb49))!important; */
    background-image: linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
    color: #fff;
    border-radius: 10px;
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    margin: 0 15px;
    text-align: center;
    font-size: 21px;
    line-height: 45px;
    text-decoration: none;
}

/* MM Error  */
.package__save br {
    display: none;
}
.check-package-info__title ~ br,
.package-info__subTitle ~ br {
    display: none;
}

.order-delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    /* background: rgba(255, 255, 255, 0.2) url(../images/icon-delivery.png) no-repeat 20px 15px; */
}
.order-delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}
.order-counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    -moz-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    -o-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    box-shadow: 0 0 24px rgba(55,38,52,0.15);
}
.order-counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}
.clearfix:before, .clearfix:after {
    display: table;
    line-height: 0;
    content: '';
}
.left {
    float: left;
}
.order-counter article.last {
    border: none;
}
.order-counter article b {
    color: #FF1C1C;
}


.paypalCred-payment .radioSec input[type="radio"] {
    appearance: auto;
    background: transparent;
    box-shadow: none;
    left: 10px;
    top: 15.5px;
}
.paypalCred-payment .paymentMethod .radioSec {
    padding-left: 25px;
}
.paypalCred-payment .radioSec .label-title{
	margin: 0;
}
.paypalCred-payment .rb-credit:checked~.creditdiv {
    padding: 5px;
}
.paypalCred-payment .errMsg {
    font-size: 12px;
}
.paypalCred-payment .cardTime-row {
    margin-left: -5px;
    margin-right: -5px;
}
.paypalCred-payment .cardTime-row .formCol {
    padding-left: 5px;
    padding-right: 5px;
}
.paypalCred-payment .form-control {
    box-shadow: none;
    border-radius: 0 !important;
}

.form__in .paypalCred-payment label.checkboxLabel{
	margin-left: 0;
}
.paypalCred-payment .checkboxLabel input[type="checkbox"]{
	top: 2px !important;
}
.form__in .paypalCred-payment.form label{
	font-weight: 600;
}
.paypalCred-payment .formCol {
    margin-bottom: 10px;
}
.paypalCred-payment .form-control {
    margin-bottom: 5px !important;
	text-overflow: ellipsis;
    overflow: hidden;
}
.paypalCred-payment select.form-control {
    padding-right: 20px;
}

.acv-checkout .package-images{
	padding-left: 6%;
}

.acv-checkout .guarantee-block .guarantee-content{
	display: flex;
    align-items: center;
}
.acv-checkout .check_area.check_area [type="checkbox"]:not(:checked)~label:before,
.acv-checkout .check_area [type="checkbox"]:checked~label:before{
	top: -1px;
}


/* Custom Radio Button  */

.acvCustom_radio[type="radio"]:checked,
.acvCustom_radio[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
	opacity: 0;
	height: unset !important;
}
.acvCustom_radio[type="radio"]:checked + label,
.acvCustom_radio[type="radio"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
	font-size: 16px !important;
}

.acvCustom_radio[type="radio"]:checked + label:before,
.acvCustom_radio[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 3.5px;
    width: 15px;
    height: 15px;
    border: 1px solid #333;
    border-radius: 30px;
    background: #fff;
}
.acvCustom_radio[type="radio"]:checked + label:before{
	border-color: #1063a0;
}
.acvCustom_radio[type="radio"]:checked + label:after,
.acvCustom_radio[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #1063a0;
    position: absolute;
    top: 6px;
    left: -12.5px;
    border-radius: 100%;
    transition: all 0.2s ease;
    border: none;
}
.acvCustom_radio[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.acvCustom_radio[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.acvCustom_radio.rb-paypal[type="radio"]:checked + label:before,
.acvCustom_radio.rb-paypal[type="radio"]:not(:checked) + label:before{
	top: 6px;	
}
.acvCustom_radio[type="radio"].rb-paypal:checked + label:after,
.acvCustom_radio[type="radio"].rb-paypal:not(:checked) + label:after{
	top: 8.5px;
}
/* .acv-checkout .masterShipAddr label{
	padding-left: 12px !important;
} */
.acv-checkout .masterShipAddr .checkboxLabel input[type="checkbox"]{
	left: -11px;
    box-shadow: none;
	border: 1px solid #333;
	accent-color: #1063a0;
	
}


.acv-checkout .masterShipAddr .cus_checkBox{
	position: relative;
	font-size: 14px;
}
.acv-checkout .masterShipAddr .checkboxLabel input[type="checkbox"]{
	opacity: 0;
}
.acv-checkout .masterShipAddr .checkboxLabel .cus_checkBox:before{
    border: 1px solid #333;
    border-radius: 4px;
    box-shadow: none;
    content: "";
    height: 15px;
    width: 15px;
    left: -24px;
    position: absolute;
    top: 3px;
}
.acv-checkout .masterShipAddr [type="checkbox"]:checked ~ .cus_checkBox:before{
	border-color: #1063a0;
	background: #1063a0;
}

.acv-checkout .masterShipAddr [type="checkbox"]:checked ~ .cus_checkBox:after{
	content: '';
    position: absolute;
    width: 9px;
    height: 4px;
    left: -21px;
    top: 7px;
    transition: all .2s;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
}
.d_none{ display: none;}
/*============================================
 Resposive  
 =============================================*/
 @media screen and (max-width:1400.5px){
	.form__in .paypalCred-payment.form label{
		font-size: 13px;
	}
	.title-block__main{
		font-size: 22px;
	}
 }
@media screen and (max-width:1199.5px){
	.title-block__main{
		font-size: 22px;
	}
	.acv-checkout .paypalCred-payment .cardTime-row .formCol{
        width: 100%;
	}
	.acv-checkout .yellowbox{
		display: block;
	}
	
}
@media screen and (max-width:1023.5px){
	.container {
		width: 900px !important;
		max-width: 100% !important;
	}
}
@media screen and (max-width:991.5px){
	.order__left,
	.order__right {
		width: 100%;
		float: none;
	}
	.acv-checkout .checkboxLabel {
        padding-left: 25px !important;		
    }
	.acv-checkout  .checkboxLabel input[type="checkbox"]{
		top: 1px !important;
	}
	.funnel_card .acv-checkout .yellowbox label {
        font-size: 14px !important;
    }
}
@media screen and (max-width:960px) and (min-width:768px){
	.acv-checkout .checkboxLabel input[type=checkbox] {
		top: -1px!important;
	}
}
@media screen and (max-width:767.5px){
	.footer-part li{
		width: 33.33%;
	}
	.order__left,
	.order__right {
		width: 100%;
		float: none;
	}
	.line .text-center{
		font-size: 1.2rem;
	}
	.product-selection{
		margin-bottom: 30px;
	}
	.package-item__status{		
		width: 15%;
	}
	.package-images{
		width: 85%;
		max-width: 450px;
	}
	.acv-checkout .package-images{
		padding: 0 20px !important;
		width: 70%;
		max-width: 100%;
	}
	.acv-checkout .package-item__content{
		float: none;
	}
	/* .package-info__subTitle{
		text-align: left;
	} */
	.package-info {
		float: left;
		text-align: center;
		width: 100%;
		padding-left: 0px;
	}
	.title-block__main{
		font-size: 14px;
	}
	.guarantee-top{
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
	}
	.delivery-block{
		min-width: 100%;
		max-width: 100%;
		margin: 0;
		font-size: 12px;
	}
	.guarantee-icon{
		float: none;
		width: 100px;
		margin-bottom: 15px;
	}
	.guarantee-text{
		padding: 0 10px;
		font-size: 13px;
	} 
	.guarantee-text p{
		font-size: 14px;
	}
	.order__header_top{
		font-size: 13px;
	}
	.order__header_in img {
		float: none;
		margin: 15px auto;
		display: block;
	}
	.package-images__item{
		padding-right: 0px;
	}
	.acv-checkout .package-item .package-info__price{
		margin-left: 0;
	}
	.acv-checkout .check-package-info__title,
	.acv-checkout .package-info__subTitle{
		padding: 0;
	}
	.acv-checkout .package-info__price span{
		margin-left: 0;
	}
	.acv-checkout .checkboxLabel {
        padding-left: 25px !important;
    }
	.acv-checkout .guarantee-block .guarantee-content{
		flex-wrap: wrap;
		justify-content: center;
	}
	.acv-checkout .line-block .arrow{
		height: 3.7rem;
	}
	
}

@media screen and (max-width: 620.9px){
	.package-images {
		width: 345px;
	}
	.package__save{
		right: 10px;
	}
	.chk-plus{
		margin-left: -13px;
		width: 25px;
	}
	.product2 .prod-btl5 {
		left: 45px;
	}
	/* .product.product3 .package-images__item .prod-btl1 {
		left: 50%;
	} */
}

@media screen and (max-width: 480px){
	.footer-part li{
		width: 100%;
	}
	.line .text-center {
		font-size: 1rem;
	}
	.form__header h2, .form__header h3{
		font-size: 1.5rem;
	}
	.steps__item {
		font-size: 13px;
		line-height: 22px;
	}
	.package-images {
		width: 230px;
		padding: 0;
	}
	.prod-btl2 {
		width: 55px;
		left: 55px;
	}
	.prod-btl3{
		width: 55px;
	}
	.prod-btl4{
		width: 55px;
	}
	.package-images__item .prod-btl1{
		/* left: 30px;
    	width: 60px; */
		height: 100%;
		width: auto;
		object-fit: contain;
	}
	.prod-btl5 {
		left: 40px;
		width: 60px;
	}
	.package__save {
		right: -45px;
		height: 75px;
		width: 75px;
		top: 30px;
	}
	.package__save_title{
		margin-top: 18px;
	}
	.chk-plus {
		margin-left: 2px;
		width: 20px;
		top: 55px;
	}
	.paymentform .cvv-link a{
		margin-left: 0 !important;
		font-size: 13px !important;
	}
	.package-item .title-block__main {
		font-size: 11px;
	}
	.shipping-row span{
		font-size: 14px;
	}
	.shipping-row span:before{
		left: -4px;
		top: 8px;
	}
	.acv-checkout .line-block .arrow {
        height: 3.5rem;
    }
}


@media screen and (max-width: 360.5px){
	#top-1 .breadcrumbs__list{
		margin-right: -14px;
	}
	.packWrapper .package1 .chk-plus{
		left: 60%;
	}
	.shipping-row span{
		padding-left: 25px;
		font-size: 13px;
	}
	.title-block__main {
		font-size: 13px;
	}
}

@media screen and (max-width: 320.5px){
	.packWrapper .chk-plus{
		left: 60%;
	}
	.packWrapper .package1 .chk-plus{
		left: 64%;
	}
}

