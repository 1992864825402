
@media screen and (orientation: portrait) and (max-width: 1024.5px){
   .acvgummiesMobile .header{
    text-align: center;
   }
}

@media screen and (max-width: 991.5px) {
    .acvgummiesMobile .header{
        text-align: center;
    }

    .acvgummiesMobile .security-icons.d-mobile{
        display: none;
    }
    .acvgummiesMobile .first-section__bottle:before, .acvgummiesMobile .first-section__bottle:after {
        opacity: 0;
    }
    .acvgummiesMobile .first-section__bottle.gummies_img ul.garante-list.row.gummies_badge li:before {
        opacity: 0;
    }
    .acvgummiesMobile .garante-list {
        list-style-type: none;
        position: absolute;
        bottom: 0;
        left: -277px;
        transform: scale(.8, .8);
        -ms-transform: scale(.8, .8);
        -webkit-transform: scale(.8, .8);
    }
    .acvgummiesMobile .garante-item_2 {
        background: url(../images/sprite1.png)  0 -237px no-repeat;
        height: 98px;
        width: 119px;
    }
    .acvgummiesMobile .garante-item_3 {
        background: url(../images/sprite1.png) 0 -432px no-repeat;
        height: 96px;
        width: 116px;
    }
    .acvgummiesMobile .first-section__info img{
        margin-bottom: 10px;
    }
}



@media only screen and (max-width: 991.9px){
    .mobileCheckout.acvMobileCheckout .top-terms {
        padding: 10px !important;
    }
    .mobileCheckout.acvMobileCheckout .acvMob-top .breadcrumbs__list{
        padding-left: 0;
    }
    .acvgummiesMobile .garante-item_1 {
        background: url(../images/sprite1.png) 0 -335px no-repeat;
        height: 97px;
        width: 112px;
    }
    .acvgummiesMobile .first-section__bottle {
        position: absolute;
        left: 350px;
        bottom: -115px;

    }
    .acvgummiesMobile .first-section__info ul{
        flex-wrap: nowrap;
    }
    .acvgummiesMobile .garante-list li {
        display: inline-block!important;
        float: left;
        padding: 0!important;
    }
    .acvgummiesMobile .first-section__bottle:before {
        opacity: 1;
        top: -45px;
        left: 5px;
    }
    .acvgummiesMobile .first-section__info .gummies_img img {
        max-width: 268px;
        margin-left: -25px;
        width: unset;
    }
    .acvgummiesMobile .garante-list {
        left: -320px;
    }
    .acvgummiesMobile .header{
        position: relative;
    }
    .acvgummiesMobile .headerMain {
        padding-top: 10px;
    }
    .acvgummiesMobile .header:before{
        display: none;
    }
    .acvgummiesMobile .header__logo {
        max-width: 122px;
        margin: 0 auto;
        display: block;
        float: none;
        margin-bottom: -15px;
    }
    .acvgummiesMobile .header:before {
        content: "";
        display: block;
        width: 197px;
        height: 24px;
        background: url(../images/arrow-head.png) no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: -100px;
        top: auto;
    }
    
    .acvgummiesMobile .first-section{
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 100px;
        background: url(../images/model.jpg) #fff no-repeat right top;
		background-size: 346px auto;
    }
    .acvgummiesMobile .first-section__side.first-section__info{
        margin-top: 0 !important;
    }
    .acvgummiesMobile .slide__item_left, .acvgummiesMobile .slide__item_right{
        width: 100%;
        padding: 0;
    }

    .acvgummiesMobile .last-block_left,  .acvgummiesMobile .last-block_right {
        width: 44%;
    }
    .acvgummiesMobile .last-block_left h3 {
        font-size: 20px;
    }
    .acvgummiesMobile .last-block_middle {
        width: 56%;
        padding-left: 10px;
    }
    .acvgummiesMobile .last-block__block .last-block_left h2 {
        font-size: 20px;
    }
    .acvgummiesMobile .last-block__block .last-block_left ul li:last-child {
        display: block;
    }
    .acvgummiesMobile .last-block__block .last-block_left ul li{
        padding: 0;
    }
    .acvgummiesMobile .review .container {
        padding-bottom: 65px;
    }
    .acvgummiesMobile .carousel-indicators {
        bottom: -38px;
    }
    .acvgummiesMobile .last-block_left{
        padding-right: 0;
    }
    .acvgummiesMobile .website-secure{
        margin-top: 0;
    }
    .acvgummiesMobile .proLand-footer p + p{
        padding-top: 0;
    }
    .shipping-form-wrapper label[for="shippingAddrCheck"] {
        font-size: 14px !important;
        position: relative;
        padding-left: 20px;
    }
    .shipping-form-wrapper label[for="shippingAddrCheck"] [type=checkbox] {
        position: absolute;
        left: 0;
        top: 3px;
        height: 15px;
        width: 15px;
    }
}


@media only screen and (max-width: 767.9px){
    
}

@media only screen and (max-width: 590.9px){
    .acvgummiesMobile .header__warning {
        font-size: 13px;       
    }
    .acvgummiesMobile .first-section__info .gummies_img img {
        max-width: 235px;
        margin-left: -75px;
    }
    #top-1 .breadcrumbs__list {
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 480.9px){
    .acvgummiesMobile .header__warning {
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 1.3;
        padding: 10px 30px;
    }
    .acvgummiesMobile .slide__item_right > div{
        min-height: 290px;
    }
    .acvgummiesMobile .first-section__info .gummies_img img {
        max-width: 205px;
        margin-left: -50px;
    }
    .acvgummiesMobile .first-section{
        padding-bottom: 68px;
    }
    .acvgummiesMobile .first-section__bottle {
        position: absolute;
        left: 240px;
        bottom: -80px;
    }
    .acvgummiesMobile .garante-list{
        left: -290px;
        bottom: -20px;
    }
    .acvgummiesMobile .gummies_badge{
        transform: scale(0.45);
    }
    .acvgummiesMobile .first-section__bottle:before {
        opacity: 1;
        top: -65px;
        left: -30px;
        transform: scale(0.8);
    }
    .acvMobileCheckout .form-partial button[type=submit], .partialsubmitbutton{
        font-size: 20px;
    }
    .package-btn{
        font-size: 16px;
    }
    .acvgummiesMobile .first-section {
        background: url(../images/model.jpg) #fff no-repeat 155px top;
        background-size: 338px auto;
    }
}

@media only screen and (max-width: 375.9px){
    .acvgummiesMobile .first-section__bottle{
        bottom: -75px;
    }
    .acvgummiesMobile .first-section__info .gummies_img img {
        max-width: 180px;
        margin-left: -50px;
        top: 0;
    }
    .acvgummiesMobile .first-section {
		background-position:  129px top;
    }
}