.upsellContainer{
    max-width: 910px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.dpillUpsell .header_upsell {
    padding: 10px 0;
    position: relative;
    border-bottom: 3px solid #b7b7b7;
}
.dpillUpsell .offer_tag {
    position: absolute;
    top: 0;
    left: 0;
}
.dPillUpsellBody {
    padding: 45px 0 70px;
}
.dPillUpsellBody h5 {
    font-size: 18px;
    color: #cb1313;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
}
.dPillUpsellBody h1 {
    font-size: 36px;
    color: #000;
    font-weight: 700;
    margin: 15px 0;
}
.dPillUpsellBody .subTitle{
    font-size: 24px;
}
.dPillUpsellBody .subTitle em{
    font-weight: 900;
}

.dPillUpsellBox .product_wrap {
    width: 865px;
    max-width: 100%;
    margin: 0px auto;
    padding: 10px 5px 5px 15px;
    border: 3px dashed #0e1118;
    position: relative;
}
.dPillUpsellBox .clearfix:after, .dPillUpsellBox .clearfix:before {
    content: " ";
    display: table;
}
.dPillUpsellBox .product_left {
    float: left;
    width: 310px;
    position: absolute;
    bottom: 0;
    top: 4%;
}
.dPillUpsellBox .product_left .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    position: absolute;
    top: 0px;
    left: -45px;
}

.dPillUpsellBox .product_left .product_pic .badge{
    position: absolute;
    left: -10px;
    top: -15px;
}
.dPillUpsellBox .product_pic {
    position: absolute;
    top: 21%;
    bottom: 0;
    width: 100%;
}
.dPillUpsellBox .product_content {
    position: relative;
    text-align: left;
    padding-left: 315px;
}
.dPillUpsellBox .product_content h2 {
    font-size: 31px;
    color: #000;
    position: relative;
    left: -50px;
    text-transform: uppercase;
    font-weight: 900;
}

.dPillUpsellBox .price_tagD {
    width: 155px;
    position: absolute;
    right: 0;
    top: 0;
}
.dPillUpsellBox .pricepic {
    width: 119px;
    height: 119px;
    background: url(../images/d-pill/price_tag.png) right bottom no-repeat;
    margin: -45px 0 0 34px;
    position: relative;
    font-size: 31px;
    color: #fff;
    padding-top: 23px;
    text-align: center;
    line-height: 35px;
    text-transform: uppercase;
    background-size: 100%;
    font-family: "Oswald", sans-serif;
    font-weight: 800;
}
.dPillUpsellBox .pricepic span {
    font-size: 29px;
    color: #fff;
    display: block;
    position: relative;
}
.dPillUpsellBox .pricepic span:after {
    width: 72px;
    height: 36px;
    position: absolute;
    content: "";
    left: 25px;
    top: 0;
    background: url(../images/d-pill/line.png) no-repeat;
    background-size: 100%;
}

.dPillUpsellBox .big_text {
    padding-right: 160px;
    margin: -10px 0 26px;
    color: #7558DA;
    font-size: 40px;
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    text-shadow: 0px 2px 2px rgba(33, 6, 52, 0.5);
    font-style: italic;
    font-weight: 900;
    font-family: "Oswald", sans-serif;
}
.dPillUpsellBox .big_text2{
    font-size: 45px;
}
.dPillUpsellBox .big_text2 > span{
    font-size: 85px;
    line-height: 70px;
    display: block;
}
.dPillUpsellBox .sub_text {
    font-size: 32px;
    margin: 45px 0 12px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    font-family: "Oswald", sans-serif;
}
.dPillUpsellBox .product_content p {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 20px;
    margin-bottom: 25px;
}
.dPillUpsellBox .price_area {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
}
.dPillUpsellBox .price_area span {
    font-size: 25px;
    /* margin-left: 15px; */
    /* display: inline-block; */
    /* margin-top: 20px; */
    color: #000;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    display: flex;
    vertical-align: middle;
    padding-bottom: 16px;  
    float: none;
}
.dPillUpsellBox .price_area span .title-block__retail{
    font-size: 25px;
    margin-left: 6px;
    margin-top: 8px;
}

.dPillUpsellBottom .only_text {
    font-size: 48px;
    color: #cb1313;
    margin: 30px 0 15px;
    text-transform: uppercase;
    font-family: 'Dharma Gothic E';
}
.dPillUpsellBottom form {
    padding: 10px;
}
.dPillUpsellBottom .checkout_btn {
    width: 660px;
    height: 87px;
    max-width: 100%;
    text-transform: uppercase;
    height: 88px;
    font-size: 30px;
    color: #fff;
    position: relative;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    -webkit-transition: 300ms ease-in-out;
    border: 1px solid #4c6436;
    background: linear-gradient(to bottom, rgba(159, 234, 97, 1) 0%, rgba(120, 189, 59, 1) 51%, rgba(71, 142, 9, 1) 100%);
    min-height: 48px;
    display: inline-block;
    line-height: 3;
    text-decoration: none;
}
.dPillUpsellBottom .dont_text {
    font-size: 12px;
    color: #8e8e8e;
    font-family: arial;
    text-transform: uppercase;
    position: relative;
}
.dPillUpsellBottom .dont_text i, .dPillUpsellBottom .dont_text span {
    display: inline-block;
    vertical-align: middle;
}
.dPillUpsellBottom .dont_text a{
    text-decoration: none;
    color: #8e8e8e;
}
.dPillUpsellBottom .dont_text i{
    position: absolute;
    top: -1px;
}
.dPillUpsellBottom .dont_text span{
    display: inline-block;
    padding-left: 15px;
}
.dpillComnt_area {
    background: #fff;
    padding: 50px 0 80px;
    border-top: 3px solid #b7b7b7;
    border-bottom: 3px solid #b7b7b7;
}
.dpillComnt_area .connection_text span {
    display: block;
    margin: 0 0 20px;
    text-align: center;
}
.dpillComnt_area .star_blog {
    color: #000;
    margin-bottom: 30px;
}
.dpillComnt_area .star_blog h4 {
    font-size: 20px;
    font-weight: 900;
    margin: 20px 0;
}
.dpillComnt_area .star_blog p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
}

@media screen and (max-width: 1024.5px) {
    .dPillUpsellBox .big_text {
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        margin: 15px 0 15px;
    }
    .dPillUpsellBox .sub_text{
        margin-top: 20px;
    }
}


@media screen and (max-width: 991.5px) {
    .dPillUpsellBox .product_left{
        width: 200px;
    }
    .dPillUpsellBox .product_content{
        padding-left: 220px;
    }
    .dPillUpsellBox .big_text{
        font-size: 35px;
    }
    .dPillUpsellBox .product_left .badge img {
        width: 90px;
    }
    .dPillUpsellBox .product_left .badge {
        left: -30px;
        top: -30px;
    }
    .dPillUpsellBox .pricepic{
        font-size: 26px;
    }
    .dPillUpsellBox .pricepic span {
        font-size: 22px;
    }
}

@media screen and (max-width: 767.5px){
    .dPillUpsellBox .product_left{
        width: 100%;
        float: none;
        position: unset;
        text-align: center;
    }
    .dPillUpsellBox .product_pic{
        position: relative;
        max-width: 200px;
        margin: 0 auto;
    }
    .dPillUpsellBox .product_left .product_pic .badge {
        position: absolute;
        left: -20px;
        top: -10px;
    }
    .dPillUpsellBox .product_wrap{
        padding: 15px;
    }
    .dPillUpsellBox .product_content h2{
        left: 0;
        margin-bottom: 15px;
    }
    .dPillUpsellBox .product_content{
        float: none;
        padding: 10px;
    }
    .dPillUpsellBox .product_left .badge {
        left: 15px;
        top: 15px;
    }
    .dPillUpsellBox .big_text2 > span{
        font-size: 60px;
        line-height: 70px;
    }
    .dPillUpsellBody .subTitle .br2{
        display: none;
    }
}

@media screen and (max-width: 480.5px){
    .dPillUpsellBox .product_content{
        text-align: center;
    }
    .dPillUpsellBox .price_area{
        font-size: 16px;
    }
    .dPillUpsellBox .price_area span{
        justify-content: center;
    }
    .dPillUpsellBox .price_tagD{
        position: unset;
        display: block;
        margin: 0 auto 15px;
    }
    .dPillUpsellBox .big_text {
        padding: 0;
        margin: 0 0 10px;
        transform: rotate(0deg);
        font-size: 25px;
    }
    .dPillUpsellBox .sub_text{
        margin-top: 10px;
        font-size: 20px;
    }
    .header_upsell span img{
        max-width: 180px;
    }
    .dpillUpsell .offer_tag{
        width: 70px;
    }
    .dPillUpsellBottom .checkout_btn{
        font-size: 20px;
        height: 60px;
    }
    .dPillUpsellBottom .only_text{
        font-size: 30px;
    }
    .dPillUpsellBox .price_area,
    .dPillUpsellBox .price_area span,
    .dPillUpsellBox .price_area span .title-block__retail{
        font-size: 20px;
        line-height: 30px;
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    .dpillComnt_area{
        padding: 45px 0;
    }
    .dPillUpsellBody .subTitle{
        font-size: 20px;
    }
    /* .body_content.text-center.upsell2 .product_left {
        width: 80px;
    } */
}