body{
    padding: 0;
    margin: 0;
}
.checkoutpage-14{
    background: url(../images/checkout14/checkout-14-bg.jpg) no-repeat !important;
    background-size: cover !important;
    padding-top: 30px;
}
.site-secure {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.inner-logo {
    padding: 45px 0 20px;
}
.inner-logo h2{
    color: #ed145b;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.payLeft {
    float: left;
    width: 67%;
    padding-left: 0;
    padding-right: 16px;
}
.newCheckout-wrapper {
    border: 0.0625rem solid #ccc;
    background-color: #fff;
    padding: 19px 17px;
    margin-bottom: 20px;
}
.newCheckout-wrapper .payProd {
    -moz-box-shadow: 0 0 9px rgba(41,32,30,.15);
    -webkit-box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    color: #000;
    position: relative;
    margin-bottom: 13px;
    height: auto;
    letter-spacing: 0px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #14345c;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer;
    font-weight: 600;
}
.newCheckout-wrapper .activePack.payProd{
    background-color: #ecd3db
}
.payProdTitle {
    padding: 10px;
    background: #ed145b;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
}

.productDiv{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;
    padding: 15px;
}
.productDiv > div:first-child{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
    padding: 0 15px;
}
.productDiv > div:last-child{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    padding: 0 15px;
}
.productDiv-img{
    padding-left: 55px !important;
    position: relative;
}
.productDiv-img .radio{
    height: 25px;
    width: 25px;
    display: inline-block;
    border: 1px solid #000;
    position: absolute;
    left: 12.5px;
    top: 50%;
    margin-top: -10px;
    background-color: #fff;
}
.productDiv-img .radio::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 15px;
    border: 3px solid #000;
    border-top: none;
    border-right: none;
    transform: rotate(-50deg);
    top: 5px;
    left: 4px;
    opacity: 0;
    visibility: hidden;
}
.activePack .productDiv-img .radio::before{
    opacity: 1;
    visibility: visible;
}
.proName {
    font-size: 18px;
    display: block;
    line-height: 1;
    font-style: italic;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 15px;
    letter-spacing: 0;
    color: #d02734;
}
.productDiv-info .price {
    vertical-align: middle;
    font-weight: 700;
    color: #0975cf;
    font-size: 36px;
    line-height: 34px;
}

.red{
    color: #cc0000;
}
.order-delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) url(../images/checkout14/icon-delivery.png) no-repeat 20px 15px;
}

.order-delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}
.order-delivery p{
    margin-top: 0;
    margin-bottom: 10px;
}
.order-counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgb(55 38 52 / 15%);
    -moz-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    -o-box-shadow: 0 0 24px rgba(55,38,52,0.15);
    box-shadow: 0 0 24px rgb(55 38 52 / 15%);
}
.order-counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}
.left {
    float: left;
}
.right {
    float: right;
}
.order-satisfaction {
    min-height: 139px;
    font-size: 13px;
    line-height: 18px;
    padding: 24px 20px 6px 140px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1) url(../images/checkout14/icon-satisfaction.png) no-repeat 20px center;
}
.order-counter article b {
    color: #FF1C1C;
}
.order-counter article.last {
    border: none;
}
.arrive-date {
    padding: 20px;
    font-size: 14px;
}
.payRight {
    float: left;
    width: 33%;
    padding-top: 0px;
}
.checkout14-form-div {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    padding: 20px 15px;
    margin-bottom: 15px;
}

.form-holder{
    margin-bottom: 10px;
}

.form-holder .form-control{
    border-radius: 0;
}

.form-div-1{
    margin-bottom: 30px;
}

.App .checkoutBtn,
.checkoutBtn{
    border: none;
    padding: 0;
    height: 75px;
    width: 100%;
    background: url(../images/checkout14/payment_rush_btn-1.png) no-repeat center top;
    background-size: contain;
    box-shadow: none;
    margin-top: 0px;
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    text-align: center;
}

.cc_form-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cc_form-holder > select{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
}
/* .cc_form-holder > select:first-child{
    margin-right: 5px;
}
.cc_form-holder > select:last-child{
    margin-left: auto;
    margin-right: 0;
} */

.cc_form-holder .cc-month,
.cc_form-holder .cc-year{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
}

.checkoutpage-14 .securityIcon-sec {
    background: #000;
    margin-top: 45px;
}

.trans img{
    object-fit: contain;
}
.freeShip {
    background: linear-gradient(0deg, rgba(196,81,0,1) 0%, rgba(245,125,2,1) 100%);
    display: inline-block;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    top: 7px;
    right: 15px;
}
.productDiv-info .price span.per {
    font-size: 12px;
}
.checkboxLabel{
    position: relative;
    padding-left: 24px;
}
.checkboxLabel .check,
.checkboxLabel input[type="checkbox"]{
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;    
    top: 3px;
}
.checkout-14-order-banner .checkboxLabel input[type="checkbox"]{
    top: 3px !important;
}



.mobileCheckout.acvMobileCheckout .proLand-footer {
    padding-bottom: 10px;
}
.mobileCheckout.acvMobileCheckout .terms-links{
    margin-bottom: 0;
}

/* Resposive  */

@media screen and (max-width:991.5px){
    .productDiv-info .price {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .instock-info {
        font-size: 13px;
        line-height: 16px;
    }
}

@media screen and (max-width:767.5px){
    .payLeft,
    .payRight{
        width: 100%;
        padding: 0 15px;
        margin-bottom: 30px;
    }
    .order-delivery {
        padding-top: 100px;
    }
}


@media screen and (max-width:480.5px){
    .productDiv > div:first-child,
    .productDiv > div:last-child{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .productDiv-img{
        margin-bottom: 20px;
    }
    .proexInfo {
        margin-top: 0;
    }
}

.prod_offer_price{
    font-size: 15px;
}