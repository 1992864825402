.tostSec{
    background-color: #fff;
}


.tostSec p{
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.searchSection{
    padding: 45px 0;
    background-color:rgb(244, 249, 255);
}

.searchDiv{
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.searchDiv input{
    border: none;
    border-radius: 0;
    background-color: transparent;
   
}

.searchDiv button{
    background: transparent;
    border: none;
    padding: 10px;
    border-left: 1px solid #ddd;
}

.searchDiv button img{
    max-width: 35px;
    opacity: 0.7;
}

.tostContent{
    padding: 50px 0;
}

.helpBtn{
    font-size: 16px;
    background: #008BD4;
    border: 1px solid #008BD4;
    color: #fff;
    padding: 10px 15px;
    font-weight: 500;
    border-radius: 10px;
}

.helpBtn:hover{
    background: #0974ad;
}

.helpSection{
    background-color: #008bd412;
    padding: 30px;
    border-left: 5px #008BD4 solid;
    margin-top: 40px;
}


@media screen and (max-width: 767.5px) {
    .helpSection{
        flex-direction: column;
    }
    .helpBtn{
        font-size: 14px;
    }
    .tostSec p{
        font-size: 14px;
    }
    .searchSection{
        padding: 15px 0;
    }
    .tostContent{
        padding: 30px 0;
    }
    
}